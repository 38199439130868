import { SuaveContainer } from '@admitkard/ccl';
import { env } from '@admitkard/uiutils/env';
import { LoginRegisterContext } from '../../context/context';
import { LoginRegisterInner } from '@admitkard/common/RegisterModal';

import React, { useContext, useEffect } from 'react';

export const Login: React.FunctionComponent = () => {
  const { setRedirectUrl } = useContext(LoginRegisterContext);

  useEffect(() => {
    setRedirectUrl('/paf?journey=AFTER_SIGNIN_FLOW');
  }, []);
  return (
    <SuaveContainer>
      <LoginRegisterInner
        heading={`Let's get started`}
        bannerBackground={env.getAssetUrl(`images/generic_banner.svg`)}
        LoginRegisterContext={LoginRegisterContext}
      />
    </SuaveContainer>
  );
};
